/* eslint-disable no-useless-escape */
/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import { toast } from "react-toastify";
import { IToggleModalTypes } from "./types";
import {
  IAddInquiryTypes,
  IInquirySupplierListRes,
} from "../redux/modules/distribution";
import { IFabricationTypes } from "../redux/modules/fabrication/types";
import { setShowModalPopup } from "../redux/actions/action";
import { downloadFile1, getData } from "../api/rest/fetchData";
import {
  adminsidebarcontent,
  App_url,
  frontofficesidebarcontent,
} from "./constants/static";
import { AddIProjectType } from "../redux/modules/projectManagement";
import { CombinedDeliveredList } from "../redux/modules/distribution/types";
import { toZonedTime } from "date-fns-tz";
import { IStockTransferForm } from "../view/pages/frontOffice/stockTransfer/AddStockOrderForm";
import { setPickupNo } from "../redux/modules/stockTransfer/action";

export const formContaint = (modal: string, name: string, submit: string) => {
  return {
    title: `${modal === "edit" ? "Edit" : modal} ${name}`,
    submit: submit ? submit : "Submit",
    // close: "Cancel",
  };
};

export const historyContaint = (modal: string, name: string) => {
  return {
    title: `${modal === "history" ? "History" : modal} ${name}`,
    //  submit: submit?submit:"Submit",
    //  close: "Cancel",
  };
};

export function UUID4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const toggleModal = (
  state: IToggleModalTypes,
  setShowModal: React.Dispatch<React.SetStateAction<IToggleModalTypes>>
) => {
  setShowModal(state);
};

export const warnContaint = (
  title: string,
  description: string,
  submit?: string,
  close?: string
) => {
  return {
    title: title,
    description: description,
    submit: submit ? submit : "Submit",
    close: close ? close : "Close",
  };
};

export function newYorkDate(dateString: string) {
  const date1 = new Date(dateString);
  return date1
    .toLocaleDateString("en-US", {
      timeZone: "America/New_York",
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    })
    .replace(/\//g, "/");
}

export const formatDate = (dateString: any): string => {
  const date = new Date(dateString);
  // Extract date components in UTC to avoid timezone issues
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // getUTCMonth is 0-based
  const day = String(date.getUTCDate()).padStart(2, "0");
  return `${month}/${day}/${year}`;
};
export const formatDateDash = (dateString: any): string => {
  const date = new Date(dateString);
  // Extract date components in UTC to avoid timezone issues
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // getUTCMonth is 0-based
  const day = String(date.getUTCDate()).padStart(2, "0");
  return `${month}-${day}-${year}`;
};

export const formatCreatedDate = (dateString: string | Date): string => {
  const date = new Date(dateString);

  // Extract day, month, and year
  const day = date.getDate();
  const month = date.getMonth() + 1; // getMonth() returns 0-based month
  const year = date.getFullYear();

  // Format to DD/MM/YYYY
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedMonth}/${formattedDay}/${year}`;
};
// export function formatDate2(dateString: string | Date): string {

//   const date = new Date(dateString);

//   // Extract date components in UTC to avoid timezone issues
//   const year = date.getUTCFullYear();
//   const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // getUTCMonth is 0-based
//   const day = String(date.getUTCDate()).padStart(2, "0");

//   // console.log(`${year}-${month}-${day}`);

//   return `${year}-${month}-${day}`;
// }
export function formatDate2(dateString: string | Date): string {
  const date = new Date(dateString);

  // Extract date components in UTC to avoid timezone issues
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth is 0-based
  const day = String(date.getDate()).padStart(2, "0");

  // console.log(`${year}-${month}-${day}`);

  return `${year}-${month}-${day}`;
}

export function getProductId(name: string, list) {
  const product = list?.items?.find((item) => {
    if (item?.product_name?.toLowerCase() === name?.toLowerCase()) {
      return item.product_id;
    }
  });
  return product;
}
export function formatDateMMDDYY(dateString: string | Date): string {
  const date = new Date(dateString);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");

  return `${month}-${day}-${year}`;
}

export function normalDate(dateString: string): string {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function getWeekday(dateString) {
  const date = new Date(dateString);
  const options: any = { weekday: "long" };
  return date.toLocaleDateString("en-US", options);
}

export const transformData = (data: any) => {
  return data?.map((item: any) => ({
    value: item.id,
    label: item.name,
  }));
};

export const roletransformData = (data: any) => {
  return data?.map((item: any) => ({
    value: item.id,
    label: item.role_name,
  }));
};

export function convertDateToMonth(dateStr) {
  const date = new Date(dateStr);
  const options: any = { year: "numeric", month: "short" };
  return date.toLocaleDateString("en-US", options);
}

export const countryData = (data: any) => {
  // if(data){
  //   return data?.map((item:any) => ({
  //     value: item.country,
  //     label: item.country,
  //   }));
  // }else{
  return [];
  // }
};
export const renderPhoneValidate = (value, textError) => {
  if (
    value?.input_value &&
    value?.format &&
    value?.input_value?.length === value?.format?.length
  ) {
    return true;
  } else {
    return textError || "Invalid valid Contact Number";
  }
};
export const YearsAgo = (years) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - years);
  return date;
};

export const CommonResponse = (data) => {
  const commonBypass = ["unauthorized", "searchService","Please provide pickup number","Pickup number not available."];
  if (data?.errors?.length > 0) {
    data?.errors?.map((item, index) => {
      if (item?.message) {
        toast.error(item?.message);
      }
    });
  } else {
    if (
      commonBypass?.includes(data?.msg) ||
      commonBypass?.includes(data?.request?.type)
    )
      return;
  }
  toast.error(data?.msg);
};

export const getPhone = (phone: string, phoneCode: string) => {
  if (phone.startsWith(phoneCode)) {
    // Remove the phone code from the start of the phone number
    return phone.substring(phoneCode.length);
  }
};

export const getEmailTemplatePayload = (formData) => {
  const payload: any = {
    email_from: "",
    email_to: "",
    email_subject: "",
    email_body: "",
    file_json: "",
  };
  if (formData?.email_from) {
    payload.email_from = formData?.email_from;
  }
  if (formData?.file_json) {
    payload.file_json = formData?.file_json;
  }
  if (formData?.email_to) {
    payload.email_to = formData?.email_to;
  }
  if (formData?.email_subject) {
    payload.email_subject = formData?.email_subject;
  }
  if (formData?.email_body) {
    payload.email_body = formData?.email_body;
  }
  if (formData?.email_tc) {
    payload.email_tc = formData?.email_tc;
  }
  if (formData?.email_notes) {
    payload.email_notes = formData?.email_notes;
  }
  return payload;
};
export const getSenderSetupPayload = (formData) => {
  const payload = {
    // account_email_host: "",
    // account_email_email: "",
    // account_email_password: "",
    // account_email_port: "",
    // account_email_type: "",
    quick_book_id: "",
    quick_book_secret: "",
    padlet_key: "",
    distribution_board_id: "",
    quick_book_env: "",
    fabrication_board_id: "",
    stock_board_id: "",
  };
  // if(formData?.account_email_host){
  //   payload.account_email_host = formData?.account_email_host
  // }
  if (formData?.fabrication_board_id) {
    payload.fabrication_board_id = formData?.fabrication_board_id;
  }
  if (formData?.stock_board_id) {
    payload.stock_board_id = formData?.stock_board_id;
  }
  // if(formData?.account_email_email){
  //   payload.account_email_email = formData?.account_email_email
  // }
  // if(formData?.account_email_password){
  //   payload.account_email_password = formData?.account_email_password
  // }
  // if(formData?.account_email_port){
  //   payload.account_email_port = formData?.account_email_port
  // }
  // if(formData?.account_email_type){
  //   payload.account_email_type = formData?.account_email_type
  // }
  if (formData?.quick_book_id) {
    payload.quick_book_id = formData?.quick_book_id;
  }
  if (formData?.quick_book_secret) {
    payload.quick_book_secret = formData?.quick_book_secret;
  }
  if (formData?.quick_book_env) {
    payload.quick_book_env = formData?.quick_book_env;
  }
  if (formData?.padlet_key) {
    payload.padlet_key = formData?.padlet_key;
  }
  if (formData?.distribution_board_id) {
    payload.distribution_board_id = formData?.distribution_board_id;
  }
  return payload;
};

export const getEmailSetupPayload = (formData) => {
  const payload = {
    account_email_host: "",
    account_email_email: "",
    account_email_password: "",
    account_email_port: "",
    account_email_type: "",
    quick_book_id: "",
    quick_book_secret: "",
    padlet_key: "",
    distribution_board_id: "",
    quick_book_env: "",
  };
  if (formData?.account_email_host) {
    payload.account_email_host = formData?.account_email_host;
  }
  if (formData?.account_email_email) {
    payload.account_email_email = formData?.account_email_email;
  }
  if (formData?.account_email_password) {
    payload.account_email_password = formData?.account_email_password;
  }
  if (formData?.account_email_port) {
    payload.account_email_port = formData?.account_email_port;
  }
  if (formData?.account_email_type) {
    payload.account_email_type = formData?.account_email_type;
  }
  if (formData?.quick_book_id) {
    payload.quick_book_id = formData?.quick_book_id;
  }
  if (formData?.quick_book_secret) {
    payload.quick_book_secret = formData?.quick_book_secret;
  }
  if (formData?.quick_book_env) {
    payload.quick_book_env = formData?.quick_book_env;
  }
  if (formData?.padlet_key) {
    payload.padlet_key = formData?.padlet_key;
  }
  if (formData?.distribution_board_id) {
    payload.distribution_board_id = formData?.distribution_board_id;
  }
  return payload;
};
export const getPayloadCustomer = (formData) => {
  const payload = {
    address: "",
    city: "",
    email: "",
    name: "",
    state: "",
    state_id: "",
    city_id: "",
    zip_code: "",
    phone: "",
    phone_code: "",
    contact_person: [],
    tax_exempt: false,
    documents: [],
  };
  if (formData?.tax_exempt != undefined) {
    payload.tax_exempt = formData?.tax_exempt;
  }
  if (formData?.address) {
    payload.address = formData?.address;
  }
  if (formData?.tax_exempt) {
    payload.tax_exempt = formData?.tax_exempt;
  }
  if (formData?.city) {
    payload.city = formData?.city;
  }
  if (formData?.email) {
    payload.email = formData?.email;
  }
  if (formData?.name) {
    payload.name = formData?.name;
  }
  if (formData?.state) {
    payload.state = formData?.state;
  }
  if (formData?.state_id) {
    payload.state_id = formData?.state_id;
  }
  if (formData?.city_id) {
    payload.city_id = formData?.city_id;
  }
  if (formData?.zip_code) {
    payload.zip_code = formData?.zip_code;
  }
  if (formData?.phoneNumber) {
    payload.phone = formData?.phoneNumber?.phone.slice(
      formData?.phoneNumber?.phone_code?.length
    );
    payload.phone_code = formData?.phoneNumber?.phone_code;
  }
  if (formData?.contact_person?.length > 0) {
    formData?.contact_person?.map((formData) => {
      const payloadData = {
        name: "",
        email: "",
        phone: "",
        phone_code: "",
      };
      if (formData?.email) {
        payloadData.email = formData?.email;
      }
      if (formData?.name) {
        payloadData.name = formData?.name;
      }
      if (formData?.phoneNumber) {
        payloadData.phone = formData?.phoneNumber?.phone.slice(
          formData?.phoneNumber?.phone_code?.length
        );
        payloadData.phone_code = formData?.phoneNumber?.phone_code;
      }
      payload.contact_person.push(payloadData);
    });
  }
  if (formData?.documents?.length > 0) {
    payload.documents = formData?.documents?.map((formData) => {
      return formData;
    });
  }
  return payload;
};

export const getDistributionPayload = (formData, param?: any) => {
  const payload: any = {
    payment_terms_id: "",
    term_condition: "",
    tax_amount: 0,
    tax_percentage: 0,
    shipping_amount: 0,
    tax: "",
    shipping_pincode: "",
    billing_pincode: "",
    supplier_id: "",
    po_number: "",
    customer_name: "",
    project_name: "",
    email: "",
    fob: "",
    via: "",
    contact_name: "",
    customer_id: "",
    inquiry_sale_products: [],
    shipping_address: "",
    city: "",
    state: "",
    country: "",
    billing_address: "",
    payment_terms: "",
    rdd: "",
    zip_code: "",
    ship_to_company_name: "",
    bill_to_company_name: "",
    status: "",
  };
  if (formData?.supplier_id) {
    payload.supplier_id = formData?.supplier_id;
  }
  if (formData?.shipping_pincode) {
    payload.shipping_pincode = formData?.shipping_pincode;
  }
  if (formData?.bill_to_company_name) {
    payload.bill_to_company_name = formData?.bill_to_company_name;
  }
  if (formData?.ship_to_company_name) {
    payload.ship_to_company_name = formData?.ship_to_company_name;
  }
  if (formData?.billing_pincode) {
    payload.billing_pincode = formData?.billing_pincode;
  }
  if (param?.supplier_id) {
    payload.id = param?.supplier_id;
  }
  if (formData?.po_number) {
    payload.po_number = formData?.po_number;
  }
  if (formData?.customer_name) {
    payload.customer_name = formData?.customer_name;
  }
  if (formData?.project_name) {
    payload.project_name = formData?.project_name;
  }
  if (formData?.email) {
    payload.email = formData?.email;
  }
  if (formData?.fob) {
    payload.fob = formData?.fob;
  }
  if (formData?.via) {
    payload.via = formData?.via;
  }
  if (formData?.contact_name) {
    payload.contact_name = formData?.contact_name;
  }
  if (formData?.customer_id) {
    payload.customer_id = formData?.customer_id;
  }
  if (formData?.billing_address) {
    payload.billing_address = formData?.billing_address;
  }
  // if (formData?.shipping_address) {
  //   payload.shipping_address = formData?.shipping_address;
  // }
  if (formData?.billingAddress) {
    payload.billing_address = formData?.billingAddress?.address;
    if (formData?.billingAddress?.city) {
      payload.city = formData?.billingAddress?.city;
    }
    if (formData?.billingAddress?.country) {
      payload.country = formData?.billingAddress?.country;
    }
    if (formData?.billingAddress?.state) {
      payload.state = formData?.billingAddress?.state;
    }
    if (formData?.billingAddress?.postal_code) {
      payload.zip_code = formData?.billingAddress?.postal_code;
      payload.billing_pincode = formData?.billingAddress?.postal_code;
    }
  }
  if (formData?.shipping_address) {
    payload.shipping_address = formData?.shipping_address;
  }
  if (formData?.shippingAddress) {
    payload.shipping_address = formData?.shippingAddress?.address;
    // payload.city = formData?.shippingAddress?.city;
    // payload.country = formData?.shippingAddress?.country;
    // payload.state = formData?.shippingAddress?.state;
    // payload.zip_code = formData?.shippingAddress?.postal_code;
    if (formData?.shippingAddress?.postal_code) {
      payload.shipping_pincode = formData?.shippingAddress?.postal_code;
    }
  }
  if (formData?.payment_terms) {
    payload.payment_terms = formData?.payment_terms;
  }
  if (formData?.payment_terms_id) {
    payload.payment_terms_id = formData?.payment_terms_id;
  }
  if (formData?.tax) {
    payload.tax = formData?.tax;
  }
  if (formData?.term_condition) {
    payload.term_condition = formData?.term_condition;
  }
  if (formData?.rdd) {
    payload.rdd = formatDate2(formData?.rdd);
  }
  if (formData?.tax_percentage) {
    payload.tax_percentage = Number(formData?.tax_percentage);
  }

  if (formData?.shipping_amount) {
    payload.shipping_amount = Number(formData?.shipping_amount);
  }
  if (formData?.inquiry_sale_products) {
    payload.inquiry_sale_products = formData?.inquiry_sale_products?.map(
      (item, index) => {
        const payloadData: any = {
          product_name: item?.product_name,
          product_qty: parseFloat(item?.product_qty),
          product_unit_id: item?.product_unit_id,
          product_unit: item?.product_unit,
          product_buy_price: parseFloat(item?.product_buy_price),
          seller_price: parseFloat(item?.seller_price),
          product_margin: parseFloat(item?.product_margin),
          total_price: parseFloat(item?.total_price),
          qb_product_name: item?.qb_product_name,
          qb_product_id: item?.qb_product_id,
        };
        if (item.id) {
          payloadData.id = item?.id;
        }
        return payloadData;
      }
    );
  }
  if (formData?.tax_amount != "") {
    payload.tax_amount = Number(formData?.tax_amount);
  }
  return payload;
};

export const getSaleOrderModulePayload = (formData, param?: any) => {
  const payload: any = {
    tax_amount: 0,
    tax_percentage: 0,
    shipping_amount: 0,
    tax: "",
    shipping_pincode: "",
    billing_pincode: "",
    supplier_id: "",
    customer_name: "",
    project_name: "",
    email: "",
    fob: "",
    via: "",
    contact_name: "",
    customer_id: "",
    product_list: [],
    shipping_address: "",
    billing_address: "",
    rdd: "",
    ship_to_company_name: "",
    bill_to_company_name: "",
    status: "",
  };

  if (formData?.bill_to_company_name) {
    payload.bill_to_company_name = formData?.bill_to_company_name;
  }
  if (formData?.ship_to_company_name) {
    payload.ship_to_company_name = formData?.ship_to_company_name;
  }
  if (formData?.customer_name) {
    payload.customer_name = formData?.customer_name;
  }
  if (formData?.project_name) {
    payload.project_name = formData?.project_name;
  }
  if (formData?.email) {
    payload.email = formData?.email;
  }
  if (formData?.fob) {
    payload.fob = formData?.fob;
  }
  if (formData?.via) {
    payload.via = formData?.via;
  }
  if (formData?.contact_name) {
    payload.contact_name = formData?.contact_name;
  }
  if (formData?.customer_id) {
    payload.customer_id = formData?.customer_id;
  }
  if (formData?.billing_address) {
    payload.billing_address = formData?.billing_address;
  }
  // if (formData?.shipping_address) {
  //   payload.shipping_address = formData?.shipping_address;
  // }
  if (formData?.billingAddress) {
    payload.billing_address = formData?.billingAddress?.address;
    if (formData?.billingAddress?.city) {
      payload.city = formData?.billingAddress?.city;
    }
    if (formData?.billingAddress?.country) {
      payload.country = formData?.billingAddress?.country;
    }
    if (formData?.billingAddress?.state) {
      payload.state = formData?.billingAddress?.state;
    }
    if (formData?.billingAddress?.postal_code) {
      payload.zip_code = formData?.billingAddress?.postal_code;
      payload.billing_pincode = formData?.billingAddress?.postal_code;
    }
  }
  if (formData?.shipping_address) {
    payload.shipping_address = formData?.shipping_address;
  }
  if (formData?.shippingAddress) {
    payload.shipping_address = formData?.shippingAddress?.address;
    if (formData?.shippingAddress?.postal_code) {
      payload.shipping_pincode = formData?.shippingAddress?.postal_code;
    }
  }

  if (formData?.tax) {
    payload.tax = formData?.tax;
  }
  if (formData?.rdd) {
    payload.rdd = formatDate2(formData?.rdd);
  }
  if (formData?.tax_percentage) {
    payload.tax_percentage = Number(formData?.tax_percentage);
  }

  if (formData?.shipping_amount) {
    payload.shipping_amount = Number(formData?.shipping_amount);
  }
  if (formData?.product_list) {
    payload.product_list = formData?.product_list?.map((item, index) => {
      const payloadData: any = {
        product_name: item?.product_name,
        product_qty: parseFloat(item?.product_qty),
        product_unit_id: item?.product_unit_id,
        product_unit: item?.product_unit,
        // product_buy_price: parseFloat(item?.product_buy_price),
        seller_price: parseFloat(item?.seller_price),
        // product_margin: parseFloat(item?.product_margin),
        total_price: parseFloat(item?.total_price),
      };
      if (item.id) {
        payloadData.id = item?.id;
      }
      return payloadData;
    });
  }
  if (formData?.tax_amount != "") {
    payload.tax_amount = Number(formData?.tax_amount);
  }
  return payload;
};
export const formatIfFloat = (value) => {
  if (typeof value === "number") {
    if (value % 1 !== 0) {
      return parseFloat(value.toFixed(4));
    }
    return value;
  }
  return value;
};

export const calculatePoundTon = (value: number, type: string) => {
  if (type === "ton") {
    return value * 2000;
  }
  if (type === "pound") {
    return value / 2000;
  }
};
export const formatTwoDigit = (value) => {
  if (!isNaN(value)) {
    let num = parseFloat(value);
    if (num >= 1e10) {
      let exponent = Math.floor(Math.log10(num));
      let normalized = num / Math.pow(10, exponent);
      return parseFloat(normalized.toFixed(4));
    }
    if (num % 1 !== 0) {
      return parseFloat(num.toFixed(4));
    }
    return num;
  }
  return value;
};

export const formatOneDigit = (value) => {
  if (!isNaN(value)) {
    let num = parseFloat(value);
    if (num >= 1e10) {
      let exponent = Math.floor(Math.log10(num));
      let normalized = num / Math.pow(10, exponent);
      return parseFloat(normalized.toFixed(1));
    }
    if (num % 1 !== 0) {
      return parseFloat(num.toFixed(1));
    }
    return num;
  }
  return value;
};

export function calculateTotalPrice(
  buyPrice,
  quantity,
  margin = null,
  sellerPrice = null
) {
  let total, calculatedMargin, calculatedSellerPrice;

  if (sellerPrice !== null) {
    total = quantity * sellerPrice;
    calculatedMargin = (sellerPrice / buyPrice - 1) * 100;
    return {
      total,
      margin: calculatedMargin,
    };
  } else if (margin !== null) {
    calculatedSellerPrice = buyPrice * (1 + margin / 100);
    total = quantity * calculatedSellerPrice;
    return {
      total,
      sellerPrice: calculatedSellerPrice,
    };
  } else {
    throw new Error("Either margin or seller price must be provided.");
  }
}
export const getCalculation = (
  buyPrice,
  quantity,
  margin = null,
  sellerPrice = null
) => {
  let result: any = {};
  try {
    if (isNaN(buyPrice) || isNaN(quantity)) {
      throw new Error("Buying Price and Quantity are required.");
    }

    if (!isNaN(sellerPrice)) {
      result = calculateTotalPrice(buyPrice, quantity, null, sellerPrice);
    } else if (!isNaN(margin)) {
      result = calculateTotalPrice(buyPrice, quantity, margin);
    } else {
      throw new Error("Either margin or seller price must be provided.");
    }

    // document.getElementById('result').innerHTML = `
    //     <strong>Total Cost:</strong> $${result.total.toFixed(2)}<br>
    //     ${result.margin !== undefined ? `<strong>Margin:</strong> ${result.margin.toFixed(2)}%<br>` : ''}
    //     ${result.sellerPrice !== undefined ? `<strong>Seller Price:</strong> $${result.sellerPrice.toFixed(2)}<br>` : ''}
    // `;
    return result;
  } catch (error) {
    document.getElementById(
      "result"
    ).innerHTML = `<strong>Error:</strong> ${error.message}`;
  }
};
export function calculateTotal(
  buyPrice,
  quantity,
  product_margin = null,
  seller_price = null
) {
  let total, calculatedMargin, calculatedSellerPrice;
  if (seller_price !== null && seller_price !== "" && seller_price > buyPrice) {
    total = quantity * seller_price;
    if (buyPrice !== 0) {
      calculatedMargin = formatIfFloat((seller_price / buyPrice - 1) * 100);
    } else {
      calculatedMargin = 0; // or some appropriate value or error handling
    }
    if (product_margin !== null && product_margin !== "") {
      calculatedSellerPrice = formatIfFloat(
        buyPrice * (1 + product_margin / 100)
      );
      total = quantity * calculatedSellerPrice;
      return {
        total: formatIfFloat(total),
        seller_price: calculatedSellerPrice,
        product_margin: calculatedMargin,
      };
    } else {
      return {
        total,
        product_margin: calculatedMargin,
      };
    }
  } else if (product_margin !== null && product_margin !== "" && buyPrice) {
    calculatedSellerPrice = formatIfFloat(
      buyPrice * (1 + product_margin / 100)
    );
    total = quantity * calculatedSellerPrice;
    return {
      total: formatIfFloat(total),
      seller_price: calculatedSellerPrice,
    };
  } else {
    calculatedSellerPrice = formatIfFloat(
      buyPrice * (1 + product_margin / 100)
    );
    total = quantity * calculatedSellerPrice;
    return {
      total: total ? formatIfFloat(total) : "",
      seller_price: calculatedSellerPrice,
    };
  }
}

export function getLowestPricedItem(
  list: IInquirySupplierListRes
): IAddInquiryTypes | undefined {
  if (!list?.items || list?.items?.length === 0) {
    return undefined;
  }

  // Filter out items with undefined or non-numeric prices
  const itemsWithPrices = list?.items?.filter(
    (item) => item?.price && !isNaN(Number(item?.price))
  );

  // Find the item with the lowest price
  let lowestPricedItem = itemsWithPrices[0];

  for (const item of itemsWithPrices) {
    if (Number(item.price) < Number(lowestPricedItem?.price)) {
      lowestPricedItem = item;
    }
  }

  return lowestPricedItem;
}
//projects payloads object
export const getPayloadProject = (formData) => {
  const payload = {
    customer_name: "",
    project_number: "",
    project_name: "",
    project_address: "",
    //specific details
    project_type: "",
    project_scope: "",
    estimated_tonnage: "",
    scope_of_work: "",
    date_of_received: "",
    date_of_completion: "",
    //Foreman Details
    foreman_name: "",
    foreman_email: "",
    foreman_contact: "",
    //Coordinator & Contractor Details
    coordinator_name: "",
    coordinator_email: "",
    coordinator_contact: "",
    submission_email: "",
    general_contractor: "",
    concrete_contractor: "",
    //other details
    architect: "",
    engineer: "",
    items_of_billing: "",
    rate: "",
    rate_of_escalation: "",
  };
  if (formData?.customer_name) {
    payload.customer_name = formData?.customer_name;
  }
  if (formData?.project_number) {
    payload.project_number = formData?.project_number;
  }
  if (formData?.project_name) {
    payload.project_name = formData?.project_name;
  }
  if (formData?.project_address) {
    payload.project_address = formData?.project_address;
  }
  if (formData?.project_type) {
    payload.project_type = formData?.project_type;
  }
  if (formData?.project_scope) {
    payload.project_scope = formData?.project_scope;
  }
  if (formData?.estimated_tonnage) {
    payload.estimated_tonnage = formData?.estimated_tonnage;
  }
  if (formData?.scope_of_work) {
    payload.scope_of_work = formData?.scope_of_work;
  }
  if (formData?.date_of_received) {
    payload.date_of_received = formData?.date_of_received;
  }
  if (formData?.date_of_completion) {
    payload.date_of_completion = formData?.date_of_completion;
  }
  if (formData?.foreman_name) {
    payload.foreman_name = formData?.foreman_name;
  }
  if (formData?.foreman_email) {
    payload.foreman_email = formData?.foreman_email;
  }
  if (formData?.coordinator_name) {
    payload.coordinator_name = formData?.coordinator_name;
  }
  if (formData?.coordinator_email) {
    payload.coordinator_email = formData?.coordinator_email;
  }
  if (formData?.coordinator_contact) {
    payload.coordinator_contact = formData?.coordinator_contact;
  }
  if (formData?.submission_email) {
    payload.submission_email = formData?.submission_email;
  }
  if (formData?.general_contractor) {
    payload.general_contractor = formData?.general_contractor;
  }
  if (formData?.concrete_contractor) {
    payload.concrete_contractor = formData?.concrete_contractor;
  }
  if (formData?.architect) {
    payload.architect = formData?.architect;
  }
  if (formData?.engineer) {
    payload.engineer = formData?.engineer;
  }
  if (formData?.items_of_billing) {
    payload.items_of_billing = formData?.items_of_billing;
  }
  if (formData?.rate) {
    payload.rate = formData?.rate;
  }
  if (formData?.rate_of_escalation) {
    payload.rate_of_escalation = formData?.rate_of_escalation;
  }
  return payload;
};

export const checkPermission = (user_data: any, data: any) => {
  const api_permissions = user_data?.user?.api_permissions?.split(",");
  if (
    user_data?.user?.role === "admin" ||
    api_permissions?.includes(`${data}`)
  ) {
    return true;
  } else {
    return false;
  }
};

export const convertLabelAdValue = (list) => {
  return list?.map((item: any, index) => {
    return { label: item?.label, value: item?.label };
  });
};

export const openPreview = (data: string, dispatch: any, fileName?: any) => {
  const binaryString = atob(data);
  const bytes = new Uint8Array(binaryString?.length);

  for (let i = 0; i < binaryString?.length; i++) {
    bytes[i] = binaryString?.charCodeAt(i);
  }

  // Create a Blob from the binary data
  const blob = new Blob([bytes], { type: "application/pdf" });

  // Generate a download URL
  const url = URL.createObjectURL(blob);
  dispatch(
    setShowModalPopup({
      show: "PREVIEW",
      data: { url: url, filename: fileName || "file.pdf" },
    })
  );
};

export const getFabricationPayload = (formData: IFabricationTypes) => {
  const payload: any = {
    product_list: [],
    project_id: "",
    project_name: "",
    customer_id: "",
    customer_name: "",
    shipping_address: "",
    billing_address: "",
    edd: "",
    note: "",
    id: "",
    tax: "",
    tax_percentage: 0,
    shipping_amount: 0,
    tax_amount: 0,
    payment_terms_id: "",
    payment_terms: "",
  };
  if (formData?.product_list?.length > 0) {
    formData?.product_list?.map((item) => {
      const payloadData = {
        product_id: "",
        product_name: "",
        specification_id: "",
        specification: "",
        grade_id: "",
        grade: "",
        coating_id: "",
        coating: "",
        supplier_type_id: "",
        supplier_type: "",
        supplier_id: "",
        supplier: "",
        id: "",
        product_qty: 0,
        total_price: 0,
        length_name: "",
        length_id: "",
        seller_price: 0,
        location_name: "",
        location_id: "",
      };
      if (item?.id) {
        payloadData.id = item?.id;
      } else {
        delete payloadData?.id;
      }
      if (item?.product_name) {
        payloadData.product_name = item?.product_name;
      }
      if (item?.product_id) {
        payloadData.product_id = item?.product_id;
      }
      if (item?.specification_id) {
        payloadData.specification_id = item?.specification_id;
      }
      if (item?.specification) {
        payloadData.specification = item?.specification;
      }
      if (item?.grade_id) {
        payloadData.grade_id = item?.grade_id;
      }
      if (item?.product_qty) {
        payloadData.product_qty = Number(item?.product_qty);
      }
      if (item?.total_price) {
        payloadData.total_price = Number(item?.total_price);
      }
      if (item?.seller_price) {
        payloadData.seller_price = Number(item?.seller_price);
      }
      if (item?.grade) {
        payloadData.grade = item?.grade;
      }
      if (item?.coating_id) {
        payloadData.coating_id = item?.coating_id;
      }
      if (item?.coating) {
        payloadData.coating = item?.coating;
      }
      if (item?.supplier_type_id) {
        payloadData.supplier_type_id = item?.supplier_type_id;
      }
      if (item?.supplier_type) {
        payloadData.supplier_type = item?.supplier_type;
      }
      if (item?.supplier_id) {
        payloadData.supplier_id = item?.supplier_id;
      }
      if (item?.length_id) {
        payloadData.length_id = item?.length_id;
      }
      if (item?.length_name) {
        payloadData.length_name = item?.length_name;
      }
      if (item?.supplier) {
        payloadData.supplier = item?.supplier;
      }
      if (formData?.location_name) {
        payloadData.location_name = formData?.location_name;
      }
      if (formData?.location_id) {
        payloadData.location_id = formData?.location_id;
      }

      payload.product_list.push(payloadData);
    });
  }
  if (formData?.shipping_address) {
    payload.shipping_address = formData?.shipping_address;
  }
  if (formData?.payment_terms) {
    payload.payment_terms = formData?.payment_terms;
  }
  if (formData?.payment_terms_id) {
    payload.payment_terms_id = formData?.payment_terms_id;
  }
  if (formData?.tax) {
    payload.tax = formData?.tax;
  }

  if (formData?.tax_amount) {
    payload.tax_amount = Number(formData?.tax_amount);
  }
  if (formData?.tax_percentage) {
    payload.tax_percentage = Number(formData?.tax_percentage);
  }
  if (formData?.shipping_amount) {
    payload.shipping_amount = Number(formData?.shipping_amount);
  }

  if (formData?.shippingAddress) {
    payload.shipping_address = formData?.shippingAddress?.address;
  }
  if (formData?.billing_address) {
    payload.billing_address = formData?.billing_address;
  }
  if (formData?.billingAddress) {
    payload.billing_address = formData?.billingAddress?.address;
  }
  if (formData?.id) {
    payload.id = formData?.id;
  } else {
    delete payload?.id;
  }

  if (formData?.project_id) {
    payload.project_id = formData?.project_id;
  }
  if (formData?.project_name) {
    payload.project_name = formData?.project_name;
  }
  if (formData?.shipping_address) {
    payload.shipping_address = formData?.shipping_address;
  }
  if (formData?.billing_address) {
    payload.billing_address = formData?.billing_address;
  }
  if (formData?.customer_id) {
    payload.customer_id = formData?.customer_id;
  }
  if (formData?.customer_name) {
    payload.customer_name = formData?.customer_name;
  }
  if (formData?.edd) {
    payload.edd = formatDate2(formData?.edd);
  }
  if (formData?.note) {
    payload.note = formData?.note;
  }
  // if (formData?.status) {
  //   payload.status = formData?.status;
  // }
  return payload;
};
export const getShopSupplyPayload = (formData: any, state?: any) => {
  console.log("formData::", formData);

  const payload: any = {
    // ...formData,
    fabrication_list: [],
    special_list: [],
    stock_list: [],
    accessories_list: [],
    project_id: "",
    project_name: "",
    customer_id: "",
    customer_name: "",
    shipping_address: "",
    billing_address: "",
    edd: "",
    note: "",
    id: "",
    tax: "",
    tax_percentage: 0,
    shipping_amount: 0,
    tax_amount: 0,
    payment_terms_id: "",
    payment_terms: "",
    total_qty: 0,
    location_id: "",
    location_name: "",
    bill_to_company_name: "",
    ship_to_company_name: "",
    project_number: "",
  };
  console.log("formData", formData);
  if (formData?.special_list?.length > 0) {
    formData?.special_list?.map((item) => {
      const fabricationData: any = {
        product_id: item.product_id || "",
        product_name: item.product_name || "",
        specification_id: item?.specification_id || "",
        specification: item?.specification || "",
        grade_id: item?.grade_id || "",
        grade: item?.grade || "",
        coating_id: item?.coating_id || "",
        coating: item?.coating || "",
        product_qty: Number(item?.product_qty || 0),
        total_price: Number(item?.total_price || 0),
        length_name: item?.length_name || "",
        length_id: item?.length_id || "",
        seller_price: Number(item?.seller_price || 0),
        location_name: formData?.location_name || "",
        location_id: formData?.location_id || "",
        qb_product_name: item?.qb_product_name,
        qb_product_id: item?.qb_product_id,
      };
      if (item?.id) {
        fabricationData.id = item?.id;
      }
      if (item?.qb_product_name) {
        fabricationData.qb_product_name = item?.qb_product_name;
      }
      if (item?.qb_product_id) {
        fabricationData.qb_product_id = item?.qb_product_id;
      }
      payload.special_list.push(fabricationData);
    });
  }
  if (formData?.fabrication_list?.length > 0) {
    formData?.fabrication_list?.map((item) => {
      const fabricationData: any = {
        product_id: item.product_id || "",
        product_name: item.product_name || "",
        specification_id: item?.specification_id || "",
        specification: item?.specification || "",
        grade_id: item?.grade_id || "",
        grade: item?.grade || "",
        coating_id: item?.coating_id || "",
        coating: item?.coating || "",
        product_qty: Number(item?.product_qty || 0),
        total_price: Number(item?.total_price || 0),
        length_name: item?.length_name || "",
        length_id: item?.length_id || "",
        seller_price: Number(item?.seller_price || 0),
        location_name: formData?.location_name || "",
        location_id: formData?.location_id || "",
        qb_product_name: item?.qb_product_name,
        qb_product_id: item?.qb_product_id,
      };
      if (item?.id) {
        fabricationData.id = item?.id;
      }
      if (item?.qb_product_name) {
        fabricationData.qb_product_name = item?.qb_product_name;
      }
      if (item?.qb_product_id) {
        fabricationData.qb_product_id = item?.qb_product_id;
      }
      payload.fabrication_list.push(fabricationData);
    });
  }

  if (formData?.stock_list?.length > 0) {
    formData?.stock_list?.map((item) => {
      const stockData: any = {
        product_id: item.product_id || "",
        product_name: item.product_name || "",
        specification_id: item?.specification_id || "",
        specification: item?.specification || "",
        grade_id: item?.grade_id || "",
        grade: item?.grade || "",
        coating_id: item?.coating_id || "",
        coating: item?.coating || "",
        product_qty: Number(item?.product_qty || 0),
        total_price: Number(item?.total_price || 0),
        length_name: item?.length_name || "",
        length_id: item?.length_id || "",
        seller_price: Number(item?.seller_price || 0),
        location_name: formData?.location_name || "",
        location_id: formData?.location_id || "",
        qb_product_name: item?.qb_product_name,
        qb_product_id: item?.qb_product_id,
      };
      if (item?.id) {
        stockData.id = item?.id;
      }
      if (item?.qb_product_name) {
        stockData.qb_product_name = item?.qb_product_name;
      }
      if (item?.qb_product_id) {
        stockData.qb_product_id = item?.qb_product_id;
      }
      payload.stock_list.push(stockData);
    });
  }

  if (formData?.accessories_list?.length > 0) {
    formData?.accessories_list?.map((item: any) => {
      const accessoriesData: any = {
        product_id: item.product_id || "",
        product_name: item.product_name || "",
        specification_id: item?.specification_id || "",
        specification: item?.specification || "",
        grade_id: item?.grade_id || "",
        grade: item?.grade || "",
        coating_id: item?.coating_id || "",
        coating: item?.coating || "",
        product_qty: Number(item?.product_qty || 0),
        total_price: Number(item?.total_price || 0),
        length_name: item?.length_name || "",
        length_id: item?.length_id || "",
        seller_price: Number(item?.seller_price || 0),
        location_name: formData?.location_name || "",
        location_id: formData?.location_id || "",
        qb_product_name: item?.qb_product_name,
        qb_product_id: item?.qb_product_id,
      };
      if (item?.id) {
        accessoriesData.id = item?.id;
      }
      if (item?.inventory_stock_id) {
        accessoriesData.inventory_stock_id = item?.inventory_stock_id;
      }
      if (item?.qb_product_name) {
        accessoriesData.qb_product_name = item?.qb_product_name;
      }
      if (item?.qb_product_id) {
        accessoriesData.qb_product_id = item?.qb_product_id;
      }
      payload.accessories_list.push(accessoriesData);
    });
  }
  if (formData?.location_id) {
    payload.location_id = formData?.location_id;
  }
  if (formData?.location_name) {
    payload.location_name = formData?.location_name;
  }
  if (formData?.ship_to_company_name) {
    payload.ship_to_company_name = formData?.ship_to_company_name;
  }
  if (formData?.bill_to_company_name) {
    payload.bill_to_company_name = formData?.bill_to_company_name;
  }
  if (formData?.shipping_address) {
    payload.shipping_address = formData?.shipping_address;
  }
  if (formData?.payment_terms) {
    payload.payment_terms = formData?.payment_terms;
  }
  if (formData?.payment_terms_id) {
    payload.payment_terms_id = formData?.payment_terms_id;
  }
  if (formData?.tax) {
    payload.tax = formData?.tax;
  }

  if (formData?.tax_amount) {
    payload.tax_amount = Number(formData?.tax_amount);
  }
  if (formData?.tax_percentage) {
    payload.tax_percentage = Number(formData?.tax_percentage);
  }
  if (formData?.shipping_amount) {
    payload.shipping_amount = roundToFixed(
      Number(formData?.shipping_amount),
      2
    );
  }

  if (formData?.shippingAddress?.address) {
    payload.shipping_address = formData?.shippingAddress?.address;
  }
  if (formData?.billing_address) {
    payload.billing_address = formData?.billing_address;
  }
  if (formData?.billingAddress) {
    payload.billing_address = formData?.billingAddress?.address;
  }
  if (formData?.id) {
    payload.id = formData?.id;
  } else {
    delete payload?.id;
  }
  if (formData?.project_id) {
    payload.project_id = formData?.project_id;
  }
  if (formData?.project_number) {
    payload.project_number = formData?.project_number;
  }
  // if (formData.project) {
  //   payload.project_no = formData?.project.project_id;
  // }

  // console.log("project.project_no ::", payload);

  if (formData?.project_name) {
    payload.project_name = formData?.project_name;
  }
  if (formData?.customer_id) {
    payload.customer_id = formData?.customer_id;
  }
  if (formData?.customer_name) {
    payload.customer_name = formData?.customer_name;
  }
  if (formData?.Project_id) {
    delete formData?.Project_id;
  }
  if (formData?.edd) {
    payload.edd = formatDate2(formData?.edd);
  }
  if (formData?.note) {
    payload.note = formData?.note;
  }

  return payload;
};

export const getInquiryPayload = (formData: IAddInquiryTypes) => {
  const payload: any = {
    customer_id: "",
    customer_name: "",
    project_name: "",
    shipping_address: "",
    billing_address: "",
    email: "",
    phone: "",
    phone_code: "",
    rdd: "",
    dor: "",
    // country_id:"",
    // state_id:"",
    // country:"",
    // state:"",
    // city:"",
    // zip_code:"",
    // address:"",
    term_condition: "",
    inquiry_products: [],
    id: "",
  };
  if (formData?.id) {
    payload.id = formData?.id;
  } else {
    delete payload?.id;
  }
  if (formData?.customer_id) {
    payload.customer_id = formData?.customer_id;
  }
  if (formData?.customer_name) {
    payload.customer_name = formData?.customer_name;
  }
  if (formData?.project_name) {
    payload.project_name = formData?.project_name;
  }
  if (formData?.billing_address) {
    payload.billing_address = formData?.billing_address;
  }
  if (formData?.billingAddress) {
    payload.billing_address = formData?.billingAddress?.address;
    if (formData?.billingAddress?.city) {
      payload.city = formData?.billingAddress?.city;
    }
    if (formData?.billingAddress?.country) {
      payload.country = formData?.billingAddress?.country;
    }
    if (formData?.billingAddress?.state) {
      payload.state = formData?.billingAddress?.state;
    }
    if (formData?.billingAddress?.postal_code) {
      payload.zip_code = formData?.billingAddress?.postal_code;
    }
  }
  if (formData?.shipping_address) {
    payload.shipping_address = formData?.shipping_address;
  }
  if (formData?.shippingAddress) {
    payload.shipping_address = formData?.shippingAddress?.address;
    // payload.city = formData?.shippingAddress?.city;
    // payload.country = formData?.shippingAddress?.country;
    // payload.state = formData?.shippingAddress?.state;
    // payload.zip_code = formData?.shippingAddress?.postal_code;
  }
  if (formData?.email) {
    payload.email = formData?.email;
  }
  if (formData?.phone_code) {
    payload.phone_code = formData?.phone_code;
  }
  if (formData?.phone) {
    payload.phone = formData?.phone;
  }
  if (formData?.dor) {
    payload.dor = formData?.dor;
  }
  if (formData?.rdd) {
    payload.rdd = formData?.rdd;
  }
  // if(formData?.country_id){
  //   payload.country_id = formData?.country_id;
  // }
  // if(formData?.state_id){
  //   payload.state_id = formData?.state_id;
  // }
  // if(formData?.country){
  //   payload.country = formData?.country;
  // }
  // if(formData?.state){
  //   payload.state = formData?.state;
  // }
  // if(formData?.city){
  //   payload.city = formData?.city;
  // }
  // if(formData?.zip_code){
  //   payload.zip_code = formData?.zip_code;
  // }
  // if(formData?.address){
  //   payload.address = formData?.address;
  // }
  if (formData?.term_condition) {
    payload.term_condition = formData?.term_condition;
  }
  if (formData?.inquiry_products) {
    formData?.inquiry_products?.map((formData) => {
      const payloadData = {
        // product_id: "",
        product_name: "",
        product_qty: null,
        product_unit: "",
        product_unit_id: "",
        // product_grade: "",
        // product_grade_id: "",
        id: "",
      };
      if (formData?.id) {
        payloadData.id = formData?.id;
      } else {
        delete payloadData?.id;
      }
      // if(formData?.product_id){
      //   payloadData.product_id = formData?.product_id;
      // }
      if (formData?.product_name) {
        payloadData.product_name = formData?.product_name;
      }
      if (formData?.product_qty) {
        payloadData.product_qty = parseFloat(formData?.product_qty);
      }
      if (formData?.product_unit) {
        payloadData.product_unit = formData?.product_unit;
      }
      if (formData?.product_unit_id) {
        payloadData.product_unit_id = formData?.product_unit_id;
      }
      // if(formData?.product_grade){
      //   payloadData.product_grade = formData?.product_grade;
      // }
      // if(formData?.product_grade_id){
      //   payloadData.product_grade_id = formData?.product_grade_id;
      // }
      payload.inquiry_products.push(payloadData);
    });
  }

  return payload;
};

export function objectCompare(obj1, obj2) {
  //Loop through properties in object 1
  for (let p in obj1) {
    //Check property exists on both objects
    if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

    switch (typeof obj1[p]) {
      //Deep compare objects
      case "object":
        if (!objectCompare(obj1[p], obj2[p])) return false;
        break;
      //Compare function code
      case "function":
        if (
          typeof obj2[p] == "undefined" ||
          (p != "compare" && obj1[p].toString() != obj2[p].toString())
        )
          return false;
        break;
      //Compare values
      default:
        if (obj1[p] != obj2[p]) return false;
    }
  }

  //Check object 2 for any extra properties
  for (let p in obj2) {
    if (typeof obj1[p] == "undefined") return false;
  }
  return true;
}

export function formatDateToYYYYMMDD(dateObj) {
  const year = dateObj.getUTCFullYear();
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
  const day = String(dateObj.getUTCDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function nextDay(date: any) {
  const TxnDate = new Date(date);
  const next: any = TxnDate.setDate(TxnDate.getDate() + 1);
  return formatDate(next);
}
// Helper function for alphanumeric sorting
const sortList = (list, key) => {
  if (list?.length > 0) {
    return list.sort((a, b) => {
      if (a[key] && b[key]) {
        const regex = /(\d+)|(\D+)/g;
        const partsA = `${a[key]}`?.match(regex);
        const partsB = `${b[key]}`?.match(regex);

        while (partsA.length && partsB.length) {
          const partA: any = partsA.shift();
          const partB: any = partsB.shift();

          // If parts are numeric, compare them as numbers
          if (!isNaN(partA) && !isNaN(partB)) {
            const numA = parseFloat(partA);
            const numB = parseFloat(partB);
            if (numA !== numB) {
              return numA - numB;
            }
          } else {
            // Else compare them as strings
            const stringCompare = partA?.localeCompare?.(partB);
            if (stringCompare !== 0) {
              return stringCompare;
            }
          }
        }

        // If all parts are equal but one string is shorter, shorter string comes first
        return partsA.length - partsB.length;
      } else {
        return a?.[key]?.localeCompare?.(b?.[key]) || 0;
      }
    });
  } else {
    return [];
  }
};

// Main sorting function for the table
export const sortTableLayout = (products, request) => {
  if (products?.length > 0) {
    const productsCopy = [...products];
    const sortBy = request?.sort_by;

    // Sort using the sortList function with the specified key
    const sortedProducts = sortList(productsCopy, sortBy);

    // Reverse the order if request?.sort_order is 'desc'
    if (request?.sort_order === "desc") {
      return sortedProducts.reverse(); // Reverse the sorted array
    }

    return sortedProducts;
  } else {
    return [];
  }
};

export const downloadInvoice = (
  data: CombinedDeliveredList,
  isDownload: boolean,
  access_token: string,
  dispatch?: any,
  setLoader?: any,
  filename?: any
) => {
  if (setLoader) setLoader(data?.invoice_id);
  getData(
    `${App_url.link.ENDPOINT_LINKS.INTUIT_DOWNLOAD}/${data?.invoice_id}`,
    access_token,
    dispatch,
    isDownload,
    filename
  )
    // downloadFile1(`${App_url.link.ENDPOINT_LINKS.INTUIT_DOWNLOAD}/${data?.invoice_id}`,`INVOICE_${data?.invoice_number}_from_KAS_METALS`,access_token)
    .then(() => {
      if (setLoader) setLoader("");
    });
};

export function extractTextFromHTML(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  return doc.body.textContent || "";
}
export function callPayloadProjects(formData: AddIProjectType) {
  const payload: any = {
    project_id: "",
    name: "",
    bill_to_company_name: "",
    ship_to_company_name: "",
    estimated_tonnage: "",
    foreman_name: "",
    foreman_email: "",
    coordinator_name: "",
    coordinator_email: "",
    submission_email_chain: "",
    general_contractor_name: "",
    concrete_contractor: "",
    architect_name: "",
    engineer_name: "",
    customer_id: "",
    project_type_id: "",
    project_scope_id: "",
    scope_of_work_id: "",
    dor: "",
    // doc: "",
    tax_exempt: false,
    customer_name: "",
    email: "",
    billing_address: "",
    shipping_address: "",
    project_type_name: "",
    project_scope_name: "",
    scope_of_work_name: "",
    shipping_pincode: "",
    billing_pincode: "",
    foreman_phone: "",
    foreman_phone_code: "",
    coordinator_phone_code: "",
    coordinator_phone: "",
    payment_terms_id: "",
    payment_terms: "",
    documents: [],
  };
  if (formData?.project_id) {
    payload.project_id = formData?.project_id;
  }
  if (formData?.payment_terms) {
    payload.payment_terms = formData?.payment_terms;
  }
  if (formData?.payment_terms_id) {
    payload.payment_terms_id = formData?.payment_terms_id;
  }
  if (formData?.name) {
    payload.name = formData?.name;
  }
  if (formData?.bill_to_company_name) {
    payload.bill_to_company_name = formData?.bill_to_company_name;
  }
  if (formData?.ship_to_company_name) {
    payload.ship_to_company_name = formData?.ship_to_company_name;
  }
  if (formData?.estimated_tonnage) {
    payload.estimated_tonnage = formData?.estimated_tonnage;
  }
  if (formData?.foreman_name) {
    payload.foreman_name = formData?.foreman_name;
  }
  if (formData?.foreman_email) {
    payload.foreman_email = formData?.foreman_email;
  }
  if (formData?.coordinator_name) {
    payload.coordinator_name = formData?.coordinator_name;
  }
  if (formData?.coordinator_email) {
    payload.coordinator_email = formData?.coordinator_email;
  }
  if (formData?.submission_email_chain) {
    payload.submission_email_chain = formData?.submission_email_chain;
  }
  if (formData?.general_contractor_name) {
    payload.general_contractor_name = formData?.general_contractor_name;
  }
  if (formData?.concrete_contractor) {
    payload.concrete_contractor = formData?.concrete_contractor;
  }
  if (formData?.architect_name) {
    payload.architect_name = formData?.architect_name;
  }
  if (formData?.engineer_name) {
    payload.engineer_name = formData?.engineer_name;
  }
  if (formData?.customer_id) {
    payload.customer_id = formData?.customer_id;
  }
  if (formData?.project_type_id) {
    payload.project_type_id = formData?.project_type_id;
  }
  if (formData?.project_scope_id) {
    payload.project_scope_id = formData?.project_scope_id;
  }
  if (formData?.scope_of_work_id) {
    payload.scope_of_work_id = formData?.scope_of_work_id;
  }
  if (formData?.dor) {
    payload.dor = formatDate2(formData?.dor);
  }
  if (formData?.doc) {
    payload.doc = formatDate2(formData?.doc);
  }
  if (formData?.tax_exempt) {
    payload.tax_exempt = formData?.tax_exempt;
  }
  if (formData?.customer_name) {
    payload.customer_name = formData?.customer_name;
  }
  if (formData?.email) {
    payload.email = formData?.email;
  }
  if (formData?.billing_address) {
    payload.billing_address = formData?.billing_address;
  }
  if (formData?.shipping_address) {
    payload.shipping_address = formData?.shipping_address;
  }
  if (formData?.project_type_name) {
    payload.project_type_name = formData?.project_type_name;
  }
  if (formData?.project_scope_name) {
    payload.project_scope_name = formData?.project_scope_name;
  }
  if (formData?.scope_of_work_name) {
    payload.scope_of_work_name = formData?.scope_of_work_name;
  }
  if (formData?.shipping_pincode) {
    payload.shipping_pincode = formData?.shipping_pincode;
  }
  if (formData?.billing_pincode) {
    payload.billing_pincode = formData?.billing_pincode;
  }
  if (formData?.shippingAddress?.address) {
    payload.shipping_address = formData?.shippingAddress?.address;
  }
  if (formData?.billingAddress?.address) {
    payload.billing_address = formData?.billingAddress?.address;
  }
  if (formData?.coordinator_phone?.phone) {
    payload.coordinator_phone = formData?.coordinator_phone?.phone;
  }
  if (formData?.coordinator_phone?.phone_code) {
    payload.coordinator_phone_code = formData?.coordinator_phone?.phone_code;
  }
  if (formData?.foreman_phone?.phone_code) {
    payload.foreman_phone_code = formData?.foreman_phone?.phone_code;
  }
  if (formData?.foreman_phone?.phone) {
    payload.foreman_phone = formData?.foreman_phone?.phone;
  }
  if (formData?.documents?.length > 0) {
    payload.documents = formData?.documents?.map((item) => item);
  }
  return payload;
}

export const FilterCommonGradeCoating = (items, isCheck = true) => {
  const uniqueItems = items?.filter(
    (item, index, self) =>
      index ===
      self?.findIndex(
        (t) =>
          t?.coating_id === item?.coating_id &&
          isCheck &&
          t?.grade_id === item?.grade_id
      )
  );
  return uniqueItems;
};
export const commonProjectItem = (formData, isId?: boolean) => {
  const payload: any = {
    coating_id: "",
    coating_name: "",
    grade_id: "",
    grade_name: "",
    unit_price: "",
    escalation: false,
    size_name: "",
    size_id: "",
  };
  if (formData?.id && isId) {
    payload.id = formData?.id;
  }
  if (formData?.accessories_name) {
    payload.accessories_name = formData?.accessories_name;
  }
  if (formData?.inventory_stock_id) {
    payload.inventory_stock_id = formData?.inventory_stock_id;
  }
  if (formData?.specification_id) {
    payload.specification_id = formData?.specification_id;
  }
  if (formData?.specification_name) {
    payload.specification_name = formData?.specification_name;
  }
  if (formData?.avg_purchase_price) {
    payload.avg_purchase_price = formData?.avg_purchase_price;
  }
  if (formData?.last_price) {
    payload.last_price = formData?.last_price;
  }
  if (formData?.product_name) {
    payload.product_name = formData?.product_name;
  }
  if (formData?.coating_id) {
    payload.coating_id = formData?.coating_id;
  }
  if (formData?.coating_name) {
    payload.coating_name = formData?.coating_name;
  }
  if (formData?.grade_id) {
    payload.grade_id = formData?.grade_id;
  }
  if (formData?.grade_name) {
    payload.grade_name = formData?.grade_name;
  }
  if (formData?.unit_price) {
    payload.unit_price = formData?.unit_price;
  }
  if (formData?.escalation) {
    payload.escalation = formData?.escalation;
  }
  if (formData?.size_name) {
    payload.size_name = formData?.size_name;
  }
  if (formData?.size_id) {
    payload.size_id = formData?.size_id;
  }
  return payload;
};
export const getProductIdByName = (name: string, products: any) => {
  const matchingProduct = products.items.find(
    (product) => product.name.toLowerCase() === name.toLowerCase()
  );
  return matchingProduct ? matchingProduct : null;
};

export const getProductSelected = (name: string, products: any) => {
  const matchingProduct = products.items.find(
    (product) => product.name.toLowerCase() === name.toLowerCase()
  );
  return matchingProduct ? matchingProduct : null;
};

export const getUTCDate = (date) => {
  if (date) {
    return new Date(toZonedTime(date, "UTC"));
  } else {
    return null;
  }
};

export const getSidebarList = (user_data) => {
  if (user_data?.user?.role === "front_office") {
    const filterFrontOffice = frontofficesidebarcontent?.filter(
      (item, index) => {
        const check = user_data?.user?.role_permissions?.filter((item1) => {
          const checkFind = item?.permission?.find(
            (item) => item?.api_list == item1
          );
          if (checkFind) {
            return true;
          }
        });
        if (check?.length) {
          return true;
        }
      }
    );
    return filterFrontOffice;
  } else if (user_data?.user?.role === "admin") {
    return adminsidebarcontent;
  } else if (user_data?.user?.role === "back_office") {
    // return backOfficeSidebarContent;
    const filterFrontOffice = frontofficesidebarcontent?.filter(
      (item, index) => {
        const check = user_data?.user?.role_permissions?.filter((item1) => {
          const checkFind = item?.permission?.find(
            (item) => item?.api_list == item1
          );
          if (checkFind) {
            return true;
          }
        });
        if (check?.length) {
          return true;
        }
      }
    );
    return filterFrontOffice;
  }
};

export const AmountFormat = (price, sign: boolean = true) => {
  if (price) {
    const number = Number(price).toLocaleString();
    console.log("NUMBER", number);

    if (!sign) {
      return `${formatIfFloat(number)}`;
    }
    return `${process.env.REACT_APP_AMOUNT_SIGN}${formatIfFloat(number)}`;
  } else {
    return "";
  }
};

export const weightFormat = (weight) => {
  if(weight)
  return weight?.toLocaleString();
};

export const ConcatListArray = (input, key_name = "id") => {
  if (input?.length > 0) {
    const result = input?.reduce?.((accumulator, current) => {
      let exists = accumulator?.find((item) => {
        return item?.[key_name] === current?.[key_name];
      });
      if (!exists) {
        accumulator = accumulator?.concat?.(current);
      }
      return accumulator;
    }, []);
    return result;
  } else {
    return [];
  }
};

export const getPayloadStockTransfer = (
  formData: IStockTransferForm,
  edit?: boolean,
  createChild?: any
) => {
  const payload: IStockTransferForm = {
    from_location_id: "",
    delivery_by_id: "",
    delivery_by: "",
    delivery_date: "",
    from_location_name: "",
    to_location_id: "",
    to_location_name: "",
    vessel_reference_number: "",
    pickup_date: "",
    main_release_reference: "",
    notes: "",
    product_list: [],
    total_weight: "",
  };
  if (formData?.from_location_id) {
    payload.from_location_id = formData?.from_location_id;
  }
  if (formData?.delivery_by_id) {
    payload.delivery_by_id = formData?.delivery_by_id;
  }
  if (formData?.delivery_by) {
    payload.delivery_by = formData?.delivery_by;
  }
  if (formData?.delivery_date) {
    payload.delivery_date = formData?.delivery_date;
  }
  if (formData?.from_location_name) {
    payload.from_location_name = formData?.from_location_name;
  }
  if (formData?.to_location_id) {
    payload.to_location_id = formData?.to_location_id;
  }
  if (formData?.to_location_name) {
    payload.to_location_name = formData?.to_location_name;
  }
  if (formData?.vessel_reference_number) {
    payload.vessel_reference_number = formData?.vessel_reference_number;
  }
  if (formData?.pickup_date) {
    payload.pickup_date = formData?.pickup_date;
  }
  if (formData?.main_release_reference) {
    payload.main_release_reference = formData?.main_release_reference;
  }
  if (formData?.notes) {
    payload.notes = formData?.notes;
  }
  if (formData?.product_list) {
    const productList = formData?.product_list?.map((item: any) => {
      const payloadData: any = {
        product_id: "",
        product_name: "",
        specification_id: "",
        specification: "",
        grade_id: "",
        grade: "",
        grade_name: "",
        coating_id: "",
        coating_name: "",
        coating: "",
        product_qty: 0,
        bundles: "",
        inventory_stock_id: "",
        to_inventory_stock_id: "",
        product_unit: "",
        product_unit_id: "",
        length_id:"",
        callOnPickUpTXNChange:""
      };
      if (edit) {
        if (item?.id) {
          payloadData.id = item?.id;
          payloadData.product_type = "OLD";
        } else {
          payloadData.product_type = "NEW";
        }
      }
      if (item?.product_id) {
        payloadData.product_id = item?.product_id;
      }
      if (item?.product_name) {
        payloadData.product_name = item?.product_name;
      }
      if (item?.specification_id) {
        payloadData.specification_id = item?.specification_id;
      }
      if (item?.specification) {
        payloadData.specification = item?.specification;
      }
      if (item?.grade_id) {
        payloadData.grade_id = item?.grade_id;
      }
      if (item?.coating) {
        payloadData.coating = item?.coating;
      }
      if (item?.length_name_lc) {
        payloadData.length_name_lc = item?.length_name_lc;
      }
      if (item?.length_id) {
        payloadData.length_id = item?.length_id;
      }
      if (item?.coating_id) {
        payloadData.coating_id = item?.coating_id;
      }
      if (item?.grade) {
        payloadData.grade = item?.grade;
      }
      if (item?.product_qty) {
        payloadData.product_qty = Number(item?.product_qty);
      }
      if (item?.bundles) {
        payloadData.bundles = item?.bundles;
      }
      if (item?.inventory_stock_id) {
        payloadData.inventory_stock_id = item?.inventory_stock_id;
      }
      if (item?.to_inventory_stock_id) {
        payloadData.to_inventory_stock_id = item?.to_inventory_stock_id;
      }
      if (item?.product_unit) {
        payloadData.product_unit = item?.product_unit;
      }
      if (item?.product_unit_id) {
        payloadData.product_unit_id = item?.product_unit_id;
      }
      return payloadData;
    });
    payload.product_list = productList;
  }
  console.log("mix_sub_list::", formData?.mix_sub_list);

  if (formData?.mix_sub_list) {
    const mix_sub_list = formData?.mix_sub_list?.map((item: any) => {
      const payloadData: any = {
        no_sub_release: "",
        name: "",
        data_json: [],
        notes: "",
        transfer_document_number: "",
        old_transfer_document_number: "",
      };
      if (edit && createChild) {
        if (item?.id) {
          payloadData.id = item?.id;
        }
      }
      if (item?.no_sub_release) {
        payloadData.no_sub_release = Number(item?.no_sub_release);
      }
      if (item?.old_transfer_document_number) {
        payloadData.old_transfer_document_number =
          item?.old_transfer_document_number;
      }
      if (item?.notes) {
        payloadData.notes = item?.notes;
      }
      if (item?.transfer_document_number) {
        payloadData.transfer_document_number = item?.transfer_document_number;
      }
      if (item?.name) {
        payloadData.name = item?.name;
      }
      if (item?.data_json) {
        payloadData.data_json = item?.data_json
          ?.filter((item) => item?.status !== "Transferred" && !item?.is_delete)
          ?.map((item) => {
            const payload: any = {
              product_id: "",
              product_name: "",
              specification_id: "",
              specification: "",
              grade_id: "",
              grade: "",
              coating_id: "",
              coating: "",
              length_id:"",
              length_name_lc:"",
              product_unit_id: "",
              product_unit: "",
              product_qty: "",
              bundles: "",
              to_inventory_stock_id: "",
            };
            if (edit) {
              if (item?.id && createChild) {
                payload.id = item?.id;
              }
              if (item?.parent_id) {
                payload.parent_id = item?.parent_id;
              }
            }
            if (item?.product_id) {
              payload.product_id = item?.product_id;
            }
            if (item?.product_name) {
              payload.product_name = item?.product_name;
            }
            if (item?.length_id) {
              payload.length_id = item?.length_id;
            }
            if (item?.length_name_lc) {
              payload.length_name_lc = item?.length_name_lc;
            }
            if (item?.specification_id) {
              payload.specification_id = item?.specification_id;
            }
            if (item?.specification) {
              payload.specification = item?.specification;
            }
            if (item?.grade_id) {
              payload.grade_id = item?.grade_id;
            }
            if (item?.grade) {
              payload.grade = item?.grade;
            }
            if (item?.coating_id) {
              payload.coating_id = item?.coating_id;
            }
            if (item?.coating) {
              payload.coating = item?.coating;
            }
            if (item?.product_unit_id) {
              payload.product_unit_id = item?.product_unit_id;
            }
            if (item?.product_unit) {
              payload.product_unit = item?.product_unit;
            }
            if (item?.product_qty) {
              payload.product_qty = Number(item?.product_qty);
            }
            if (item?.per_release_weight) {
              payload.product_qty = Number(item?.per_release_weight);
            }
            if (item?.bundles) {
              payload.bundles = item?.bundles;
            }
            if (item?.to_inventory_stock_id) {
              payload.to_inventory_stock_id = item?.to_inventory_stock_id;
            }
            return payload;
          });
      }
      return payloadData;
    });
    payload.mix_sub_list = mix_sub_list;
  }
  if (formData?.mix_sub_list) {
    let edit_list = formData?.mix_sub_list
      ?.filter((item) => item?.id && item?.data_json?.length > 0)
      ?.map((item: any) => {
        const payloadData: any = {
          name: "",
          product_list: [],
          from_location_id: "",
          from_location_name: "",
          from_location_name_lc: "",
          to_location_id: "",
          to_location_name: "",
          to_location_name_lc: "",
          pickup_date: "",
          delivery_date: "",
          delivery_by: "",
          delivery_by_lc: "",
          delivery_by_id: "",
          transfer_document_number: "",
          vessel_reference_number: "",
          main_release_reference: "",
          status: "",
          file_json: "",
          file_json_at: "",
          total_weight: "",
          remaining_weight: "",
          child_number: "",
          file_json_by: "",
          sub_release_id: "",
          sub_release_type: "",
          notes: "",
          sub_release_count: "",
          has_child: "",
          is_child: "",
          created_at: "",
          updated_at: "",
          deleted_at: "",
          author: "",
          no_sub_release: "",
          old_transfer_document_number: "",
        };
        if (edit && createChild) {
          if (item?.id) {
            payloadData.id = item?.id;
          }
          if (item?.parent_id) {
            payloadData.parent_id = item?.parent_id;
          }
        }
        if (item?.no_sub_release) {
          payloadData.no_sub_release = Number(item?.no_sub_release);
        }
        if (item?.old_transfer_document_number || item?.transfer_document_number) {
          payloadData.old_transfer_document_number =
            item?.old_transfer_document_number || item?.transfer_document_number;
        }
        if (item?.name) {
          payloadData.name = item?.name;
        }
        if (item?.from_location_id) {
          payloadData.from_location_id = item?.from_location_id;
        }
        if (item?.from_location_name) {
          payloadData.from_location_name = item?.from_location_name;
        }
        if (item?.from_location_name_lc) {
          payloadData.from_location_name_lc = item?.from_location_name_lc;
        }
        if (item?.to_location_id) {
          payloadData.to_location_id = item?.to_location_id;
        }
        if (item?.to_location_name) {
          payloadData.to_location_name = item?.to_location_name;
        }
        if (item?.to_location_name_lc) {
          payloadData.to_location_name_lc = item?.to_location_name_lc;
        }
        if (item?.pickup_date) {
          payloadData.pickup_date = item?.pickup_date;
        }
        if (item?.delivery_date) {
          payloadData.delivery_date = item?.delivery_date;
        }
        if (item?.delivery_by) {
          payloadData.delivery_by = item?.delivery_by;
        }
        if (item?.delivery_by_lc) {
          payloadData.delivery_by_lc = item?.delivery_by_lc;
        }
        if (item?.delivery_by_id) {
          payloadData.delivery_by_id = item?.delivery_by_id;
        }
        if (item?.transfer_document_number) {
          payloadData.transfer_document_number = item?.transfer_document_number;
        }
        if (item?.vessel_reference_number) {
          payloadData.vessel_reference_number = item?.vessel_reference_number;
        }
        if (item?.main_release_reference) {
          payloadData.main_release_reference = item?.main_release_reference;
        }
        if (item?.status) {
          payloadData.status = item?.status;
        }
        if (item?.file_json) {
          payloadData.file_json = item?.file_json;
        }
        if (item?.file_json_at) {
          payloadData.file_json_at = item?.file_json_at;
        }
        if (item?.total_weight) {
          payloadData.total_weight = item?.total_weight;
        }
        if (item?.remaining_weight) {
          payloadData.remaining_weight = item?.remaining_weight;
        }
        if (item?.child_number) {
          payloadData.child_number = item?.child_number;
        }
        if (item?.file_json_by) {
          payloadData.file_json_by = item?.file_json_by;
        }
        if (item?.sub_release_id) {
          payloadData.sub_release_id = item?.sub_release_id;
        }
        if (item?.sub_release_type) {
          payloadData.sub_release_type = item?.sub_release_type;
        }
        if (item?.notes) {
          payloadData.notes = item?.notes;
        }
        if (item?.sub_release_count) {
          payloadData.sub_release_count = item?.sub_release_count;
        }
        if (item?.has_child) {
          payloadData.has_child = item?.has_child;
        }
        if (item?.is_child) {
          payloadData.is_child = item?.is_child;
        }
        if (item?.created_at) {
          payloadData.created_at = item?.created_at;
        }
        if (item?.updated_at) {
          payloadData.updated_at = item?.updated_at;
        }
        if (item?.deleted_at) {
          payloadData.deleted_at = item?.deleted_at;
        }
        if (item?.author) {
          payloadData.author = item?.author;
        }
        const item1 = item;
        if (item?.data_json) {
          payloadData.product_list = item?.data_json
            ?.filter(
              (item) =>
                item?.status !== "Transferred" &&
                ((!item?.is_delete && !item?.id) || item?.id)
            )
            ?.map((item) => {
              const payload: any = {
                product_id: "",
                product_name: "",
                specification_id: "",
                specification: "",
                grade_id: "",
                grade: "",
                length_id:"",
                length_name_lc:"",
                coating_id: "",
                coating: "",
                product_unit_id: "",
                product_unit: "",
                product_qty: "",
                bundles: "",
                to_inventory_stock_id: "",
              };
              if (edit) {
                if (item?.id && createChild) {
                  payload.id = item?.id;
                }
                if (item?.parent_id) {
                  payload.parent_id = item?.parent_id;
                }
              }
              if (!item1?.id && item.id) {
                delete item.id;
              }
              if (item?.product_id) {
                payload.product_id = item?.product_id;
              }
              if (item?.id == item?.parent_id) {
                delete payload.id;
                delete item.id;
              }
              payload.product_type = item?.id ? "OLD" : "NEW";
              if (item?.is_delete) {
                payload.product_type = "DEL";
              }
              if (item?.product_name) {
                payload.product_name = item?.product_name;
              }
              if (item?.specification_id) {
                payload.specification_id = item?.specification_id;
              }
              if (item?.length_id) {
                payload.length_id = item?.length_id;
              }
              if (item?.length_name_lc) {
                payload.length_name_lc = item?.length_name_lc;
              }
              if (item?.specification) {
                payload.specification = item?.specification;
              }
              if (item?.grade_id) {
                payload.grade_id = item?.grade_id;
              }
              if (item?.grade) {
                payload.grade = item?.grade;
              }
              if (item?.coating_id) {
                payload.coating_id = item?.coating_id;
              }
              if (item?.coating) {
                payload.coating = item?.coating;
              }
              if (item?.product_unit_id) {
                payload.product_unit_id = item?.product_unit_id;
              }
              if (item?.product_unit) {
                payload.product_unit = item?.product_unit;
              }
              if (item?.product_qty) {
                payload.product_qty = Number(item?.product_qty);
              }
              if (item?.per_release_weight) {
                payload.product_qty = Number(item?.per_release_weight);
              }
              if (item?.bundles) {
                payload.bundles = item?.bundles;
              }
              if (item?.to_inventory_stock_id) {
                payload.to_inventory_stock_id = item?.to_inventory_stock_id;
              }

              return payload;
            });
        }
        if (payloadData.product_list.length > 0) {
          return payloadData;
        }
      });
    edit_list = edit_list.filter((item) => item !== undefined);
    payload.edit_list = edit_list;
  }

  if (formData?.delete_list) {
    console.log("DELETELIST",formData?.delete_list);
    
    const delete_list = formData?.delete_list?.map((item: any) => {
      const payloadData: any = {
        no_sub_release: "",
        name: "",
        data_json: [],
        from_location_id: "",
        from_location_name: "",
        from_location_name_lc: "",
        to_location_id: "",
        to_location_name: "",
        to_location_name_lc: "",
        pickup_date: "",
        delivery_date: "",
        delivery_by: "",
        delivery_by_lc: "",
        delivery_by_id: "",
        transfer_document_number: "",
        vessel_reference_number: "",
        main_release_reference: "",
        status: "",
        file_json: "",
        file_json_at: "",
        total_weight: "",
        remaining_weight: "",
        child_number: "",
        file_json_by: "",
        sub_release_id: "",
        sub_release_type: "",
        notes: "",
        sub_release_count: "",
        
        has_child: "",
        is_child: "",
        parent_id: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        author: "",
      };
      if (edit && createChild) {
        if (item?.id) {
          payloadData.id = item?.id;
        }
        if (item?.parent_id) {
          payloadData.parent_id = item?.parent_id;
        }
      }
      if (item?.from_location_id) {
        payloadData.from_location_id = item?.from_location_id;
      }
      if (item?.from_location_name) {
        payloadData.from_location_name = item?.from_location_name;
      }
      
      if (item?.from_location_name_lc) {
        payloadData.from_location_name_lc = item?.from_location_name_lc;
      }
      if (item?.to_location_id) {
        payloadData.to_location_id = item?.to_location_id;
      }
      if (item?.to_location_name) {
        payloadData.to_location_name = item?.to_location_name;
      }
      if (item?.to_location_name_lc) {
        payloadData.to_location_name_lc = item?.to_location_name_lc;
      }
      if (item?.pickup_date) {
        payloadData.pickup_date = item?.pickup_date;
      }
      if (item?.delivery_date) {
        payloadData.delivery_date = item?.delivery_date;
      }
      if (item?.delivery_by) {
        payloadData.delivery_by = item?.delivery_by;
      }
      if (item?.delivery_by_lc) {
        payloadData.delivery_by_lc = item?.delivery_by_lc;
      }
      if (item?.delivery_by_id) {
        payloadData.delivery_by_id = item?.delivery_by_id;
      }
      if (item?.transfer_document_number) {
        payloadData.transfer_document_number = item?.transfer_document_number;
      }
      if (item?.vessel_reference_number) {
        payloadData.vessel_reference_number = item?.vessel_reference_number;
      }
      if (item?.main_release_reference) {
        payloadData.main_release_reference = item?.main_release_reference;
      }
      if (item?.status) {
        payloadData.status = item?.status;
      }
      if (item?.file_json) {
        payloadData.file_json = item?.file_json;
      }
      if (item?.file_json_at) {
        payloadData.file_json_at = item?.file_json_at;
      }
      if (item?.total_weight) {
        payloadData.total_weight = item?.total_weight;
      }
      if (item?.remaining_weight) {
        payloadData.remaining_weight = item?.remaining_weight;
      }
      if (item?.child_number) {
        payloadData.child_number = item?.child_number;
      }
      if (item?.file_json_by) {
        payloadData.file_json_by = item?.file_json_by;
      }
      if (item?.sub_release_id) {
        payloadData.sub_release_id = item?.sub_release_id;
      }
      if (item?.sub_release_type) {
        payloadData.sub_release_type = item?.sub_release_type;
      }
      if (item?.notes) {
        payloadData.notes = item?.notes;
      }
      if (item?.sub_release_count) {
        payloadData.sub_release_count = item?.sub_release_count;
      }
      if (item?.has_child) {
        payloadData.has_child = item?.has_child;
      }
      if (item?.is_child) {
        payloadData.is_child = item?.is_child;
      }
      if (item?.created_at) {
        payloadData.created_at = item?.created_at;
      }
      if (item?.updated_at) {
        payloadData.updated_at = item?.updated_at;
      }
      if (item?.deleted_at) {
        payloadData.deleted_at = item?.deleted_at;
      }
      if (item?.author) {
        payloadData.author = item?.author;
      }
      if (item?.no_sub_release) {
        payloadData.no_sub_release = Number(item?.no_sub_release);
      }
      if (item?.name) {
        payloadData.name = item?.name;
      }
      if (item?.data_json) {
        payloadData.data_json = item?.data_json
          ?.filter((item) => item?.status !== "Transferred")
          ?.map((item) => {
            const payload: any = {
              product_id: "",
              product_name: "",
              specification_id: "",
              specification: "",
              grade_id: "",
              grade: "",
              coating_id: "",
              coating: "",
              product_unit_id: "",
              product_unit: "",
              product_qty: "",
              bundles: "",
              to_inventory_stock_id: "",
              length_id:"",
              length_name_lc:"",
              
            };
            if (edit) {
              if (item?.id && createChild) {
                payload.id = item?.id;
              }
              if (item?.parent_id) {
                payload.parent_id = item?.parent_id;
              }
            }
            if (item?.length_id) {
              payload.length_id = item?.length_id;
            }
            if (item?.length_name_lc) {
              payload.length_name_lc = item?.length_name_lc;
            }
            if (item?.product_id) {
              payload.product_id = item?.product_id;
            }
            if (item?.product_name) {
              payload.product_name = item?.product_name;
            }
            if (item?.specification_id) {
              payload.specification_id = item?.specification_id;
            }
            if (item?.specification) {
              payload.specification = item?.specification;
            }
            if (item?.grade_id) {
              payload.grade_id = item?.grade_id;
            }
            if (item?.grade) {
              payload.grade = item?.grade;
            }
            if (item?.coating_id) {
              payload.coating_id = item?.coating_id;
            }
            if (item?.coating) {
              payload.coating = item?.coating;
            }
            if (item?.product_unit_id) {
              payload.product_unit_id = item?.product_unit_id;
            }
            if (item?.product_unit) {
              payload.product_unit = item?.product_unit;
            }
            if (item?.product_qty) {
              payload.product_qty = Number(item?.product_qty);
            }
            if (item?.per_release_weight) {
              payload.product_qty = Number(item?.per_release_weight);
            }
            if (item?.bundles) {
              payload.bundles = item?.bundles;
            }
            if (item?.to_inventory_stock_id) {
              payload.to_inventory_stock_id = item?.to_inventory_stock_id;
            }
            return payload;
          });
      }
      return payloadData;
    });
    payload.delete_list = delete_list;
  }
  if (
    formData?.multi_sub_list &&
    formData?.multi_sub_list?.filter?.((item) => item?.is_edit)?.length > 0
  ) {
    payload.multi_sub_list = formData?.multi_sub_list
      ?.filter((item) => item?.is_edit)
      ?.map((item) => {
        const payloadData: any = {
          product_id: "",
          product_name: "",
          specification_id: "",
          specification: "",
          grade_id: "",
          grade: "",
          coating_id: "",
          coating: "",
          product_unit_id: "",
          product_unit: "",
          product_qty: "",
          no_sub_release: "",
          bundles: "",
          to_inventory_stock_id: "",
        };
        if (edit) {
          if (item?.id && createChild) {
            payloadData.id = item?.id;
          }
        }
        if (item?.product_id) {
          payloadData.product_id = item?.product_id;
        }
        if (item?.product_name) {
          payloadData.product_name = item?.product_name;
        }
        if (item?.specification_id) {
          payloadData.specification_id = item?.specification_id;
        }
        if (item?.specification) {
          payloadData.specification = item?.specification;
        }
        if (item?.grade_id) {
          payloadData.grade_id = item?.grade_id;
        }
        if (item?.grade) {
          payloadData.grade = item?.grade;
        }
        if (item?.coating_id) {
          payloadData.coating_id = item?.coating_id;
        }
        if (item?.coating) {
          payloadData.coating = item?.coating;
        }
        if (item?.product_unit_id) {
          payloadData.product_unit_id = item?.product_unit_id;
        }
        if (item?.product_unit) {
          payloadData.product_unit = item?.product_unit;
        }
        if (item?.product_qty) {
          payloadData.product_qty = Number(item?.product_qty);
        }
        if (item?.per_release_weight) {
          payloadData.product_qty = Number(item?.per_release_weight);
        }
        if (item?.no_sub_release) {
          payloadData.no_sub_release = Number(item?.no_sub_release);
        }
        if (item?.bundles) {
          payloadData.bundles = item?.bundles;
        }
        if (item?.to_inventory_stock_id) {
          payloadData.to_inventory_stock_id = item?.to_inventory_stock_id;
        }
        return payloadData;
      });
  }
  return payload;
};
export const getMixPayloadEdit = (formData) => {
  const payload = {
    id: "",
    from_location_id: "",
    from_location_name: "",
    from_location_name_lc: "",
    to_location_id: "",
    to_location_name: "",
    to_location_name_lc: "",
    pickup_date: "",
    delivery_date: "",
    delivery_by: "",
    delivery_by_lc: "",
    delivery_by_id: "",
    transfer_document_number: "",
    vessel_reference_number: "",
    main_release_reference: "",
    status: "",
    file_json: "",
    file_json_at: "",
    total_weight: "",
    remaining_weight: "",
    child_number: "",
    file_json_by: "",
    sub_release_id: "",
    sub_release_type: "",
    notes: "",
    sub_release_count: 0,
    has_child: "",
    is_child: "",
    parent_id: "",
    created_at: "",
    updated_at: "",
    deleted_at: "",
    author: "",
    no_sub_release: "",
  };
  if (formData?.id) {
    payload.id = formData?.id;
  }
  if (formData?.from_location_id) {
    payload.from_location_id = formData?.from_location_id;
  }
  if (formData?.from_location_name) {
    payload.from_location_name = formData?.from_location_name;
  }
  if (formData?.from_location_name_lc) {
    payload.from_location_name_lc = formData?.from_location_name_lc;
  }
  if (formData?.to_location_id) {
    payload.to_location_id = formData?.to_location_id;
  }
  if (formData?.to_location_name) {
    payload.to_location_name = formData?.to_location_name;
  }
  if (formData?.to_location_name_lc) {
    payload.to_location_name_lc = formData?.to_location_name_lc;
  }
  if (formData?.pickup_date) {
    payload.pickup_date = formData?.pickup_date;
  }
  if (formData?.delivery_date) {
    payload.delivery_date = formData?.delivery_date;
  }
  if (formData?.delivery_by) {
    payload.delivery_by = formData?.delivery_by;
  }
  if (formData?.delivery_by_lc) {
    payload.delivery_by_lc = formData?.delivery_by_lc;
  }
  if (formData?.delivery_by_id) {
    payload.delivery_by_id = formData?.delivery_by_id;
  }
  if (formData?.transfer_document_number) {
    payload.transfer_document_number = formData?.transfer_document_number;
  }
  if (formData?.vessel_reference_number) {
    payload.vessel_reference_number = formData?.vessel_reference_number;
  }
  if (formData?.main_release_reference) {
    payload.main_release_reference = formData?.main_release_reference;
  }
  if (formData?.status) {
    payload.status = formData?.status;
  }
  if (formData?.file_json) {
    payload.file_json = formData?.file_json;
  }
  if (formData?.file_json_at) {
    payload.file_json_at = formData?.file_json_at;
  }
  if (formData?.total_weight) {
    payload.total_weight = formData?.total_weight;
  }
  if (formData?.remaining_weight) {
    payload.remaining_weight = formData?.remaining_weight;
  }
  if (formData?.child_number) {
    payload.child_number = formData?.child_number;
  }
  if (formData?.file_json_by) {
    payload.file_json_by = formData?.file_json_by;
  }
  if (formData?.sub_release_id) {
    payload.sub_release_id = formData?.sub_release_id;
  }
  if (formData?.sub_release_type) {
    payload.sub_release_type = formData?.sub_release_type;
  }
  if (formData?.notes) {
    payload.notes = formData?.notes;
  }
  if (formData?.sub_release_count) {
    payload.sub_release_count = Number(formData?.sub_release_count);
  }
  if (formData?.has_child) {
    payload.has_child = formData?.has_child;
  }
  if (formData?.is_child) {
    payload.is_child = formData?.is_child;
  }
  if (formData?.parent_id) {
    payload.parent_id = formData?.parent_id;
  }
  if (formData?.created_at) {
    payload.created_at = formData?.created_at;
  }
  if (formData?.updated_at) {
    payload.updated_at = formData?.updated_at;
  }
  if (formData?.deleted_at) {
    payload.deleted_at = formData?.deleted_at;
  }
  if (formData?.author) {
    payload.author = formData?.author;
  }
  if (formData?.no_sub_release) {
    payload.no_sub_release = formData?.no_sub_release;
  }
  return payload;
};

export const getEditStockTransferPayload = (formData: IStockTransferForm) => {
  const payload: IStockTransferForm = {
    from_location_id: "",
    delivery_by_id: "",
    delivery_by: "",
    delivery_date: "",
    from_location_name: "",
    to_location_id: "",
    to_location_name: "",
    vessel_reference_number: "",
    pickup_date: "",
    main_release_reference: "",
    notes: "",
    product_list: [],
    total_weight: "",
    transfer_document_number:""
    

  };
  if (formData?.from_location_id) {
    payload.from_location_id = formData?.from_location_id;
  }
  if (formData?.transfer_document_number) {
    payload.transfer_document_number = formData?.transfer_document_number;
  }
  if (formData?.delivery_by_id) {
    payload.delivery_by_id = formData?.delivery_by_id;
  }
  if (formData?.delivery_by) {
    payload.delivery_by = formData?.delivery_by;
  }
  if (formData?.delivery_date) {
    payload.delivery_date = formData?.delivery_date;
  }
  if (formData?.from_location_name) {
    payload.from_location_name = formData?.from_location_name;
  }
  if (formData?.to_location_id) {
    payload.to_location_id = formData?.to_location_id;
  }
  if (formData?.to_location_name) {
    payload.to_location_name = formData?.to_location_name;
  }
  if (formData?.vessel_reference_number) {
    payload.vessel_reference_number = formData?.vessel_reference_number;
  }
  if (formData?.pickup_date) {
    payload.pickup_date = formData?.pickup_date;
  }
  if (formData?.main_release_reference) {
    payload.main_release_reference = formData?.main_release_reference;
  }
  if (formData?.notes) {
    payload.notes = formData?.notes;
  }
  if (formData?.product_list) {
    const productList = formData?.product_list?.map((item: any) => {
      const payloadData: any = {
        id: "",
        product_type: "",
        parent_id: "",
        product_id: "",
        product_name: "",
        specification_id: "",
        specification: "",
        grade_id: "",
        grade: "",
        coating_id: "",
        coating: 0,
        product_unit_id: "",
        product_unit: "",
        product_qty: "",
        bundles: "",
        to_inventory_stock_id: "",
        length_name_lc:"",
        length_id:""
      };
      if (item?.id) {
        payloadData.id = item?.id;
      }
      if (item?.product_type) {
        payloadData.product_type = item?.product_type;
      }
      if (item?.parent_id) {
        payloadData.parent_id = item?.parent_id;
      }
      if (item?.product_id) {
        payloadData.product_id = item?.product_id;
      }
      if (item?.product_name) {
        payloadData.product_name = item?.product_name;
      }
      if (item?.length_name_lc) {
        payloadData.length_name_lc = item?.length_name_lc;
      }
      if (item?.length_id) {
        payloadData.length_id = item?.length_id;
      }
      if (item?.specification_id) {
        payloadData.specification_id = item?.specification_id;
      }
      if (item?.specification) {
        payloadData.specification = item?.specification;
      }
      if (item?.grade_id) {
        payloadData.grade_id = item?.grade_id;
      }
      if (item?.grade) {
        payloadData.grade = item?.grade;
      }
      if (item?.coating_id) {
        payloadData.coating_id = item?.coating_id;
      }
      if (item?.coating) {
        payloadData.coating = item?.coating;
      }
      if (item?.product_unit_id) {
        payloadData.product_unit_id = item?.product_unit_id;
      }
      if (item?.product_unit) {
        payloadData.product_unit = item?.product_unit;
      }
      if (item?.product_qty) {
        payloadData.product_qty = Number(item?.product_qty);
      }
      if (item?.bundles) {
        payloadData.bundles = item?.bundles;
      }
      if (item?.to_inventory_stock_id) {
        payloadData.to_inventory_stock_id = item?.to_inventory_stock_id;
      }
      return payloadData;
    });
    payload.product_list = productList;
  }
  return payload;
};

export function shortenText(text, maxLength = 39) {
  if (text.length <= maxLength) {
    return text; // If the text is less than or equal to max length, return as is.
  }

  const extensionMatch = text.match(/\.[^\.]+$/); // Regular expression to find the file extension
  const extension = extensionMatch ? extensionMatch[0] : ""; // Extract the extension or set it to empty if not found

  const remainingLength = maxLength - extension.length - 3; // Subtract 3 for "..."
  const startLength = Math.floor(remainingLength * 0.7); // Show more of the start (e.g., 70%)
  const endLength = remainingLength - startLength; // The remaining part goes to the end part

  const start = text.slice(0, startLength); // First part of the text
  const end = text.slice(-endLength - extension.length); // End part including the extension

  return `${start}...${end}`; // Combine start, ellipses, and end parts
}
export function RemovePhoneCode(phone: string, phone_code: string) {
  if (phone) {
    return phone?.slice(phone_code?.length);
  } else {
    return "";
  }
}

export const calculateOvertimeTotal = (
  overtime_qty,
  overtime_unit_price,
  setTotal
) => {
  const total: any = parseFloat(overtime_qty) * parseFloat(overtime_unit_price);
  if (!isNaN(total)) {
    setTotal(parseFloat(total?.toFixed(2))?.toString());
  } else {
    setTotal("");
  }
};

export const callDownloadPDF = async (setLoader, data, user_data, dispatch) => {
  const file_json = JSON.parse(data?.file_json);
  setLoader(data?.id);
  // downloadInvoice(data, false, user_data.access_token, dispatch, setLoader, `INVOICE_${data?.invoice_number}_from_KAS_METALS.pdf`)
  if (file_json?.original_name?.includes(".pdf")) {
    await getData(
      `${App_url.link.ENDPOINT_LINKS.BIL_OF_LOADING_DOWNLOAD}/${file_json?.name}`,
      user_data?.access_token,
      dispatch,
      false,
      `${data?.transfer_document_number}`
    );
  } else {
    await downloadFile1(
      `${App_url.link.ENDPOINT_LINKS.BIL_OF_LOADING_DOWNLOAD}/${file_json?.name}`,
      `${data?.transfer_document_number}`,
      user_data?.access_token
    );
  }
  setTimeout(() => setLoader(""), 500);
};

export const shopInvoiceProduct = (socketResponse, products) => {
  const inquiry_products = [];
  if (socketResponse?.data?.note) {
    const rebar = getProductIdByName("Rebar", products);
    inquiry_products.push({
      product_name: "Rebar",
      product_qty: 1,
      seller_price: 0,
      total_price: 0,
      qb_product_id: rebar?.qb_product_id,
      qb_product_name: rebar?.qb_product_name,
      product_type: "note",
      note: socketResponse?.data?.note,
    });
  }
  socketResponse?.data?.fabrication_list?.map((item) =>
    inquiry_products.push({ ...item, product_type: "fabrications" })
  );
  socketResponse?.data?.special_list?.map((item) =>
    inquiry_products.push({ ...item, product_type: "special" })
  );
  socketResponse?.data?.stock_list?.map((item) =>
    inquiry_products.push({ ...item, product_type: "stock" })
  );
  socketResponse?.data?.accessories_list?.map((item) =>
    inquiry_products.push({ ...item, product_type: "accessories" })
  );

  return inquiry_products;
};

export function generateUniqueId() {
  const prefix = "KM-";
  const randomNum = Math.floor(Math.random() * (99999 - 20001 + 1)) + 20001; // Random number between 20001 and 99999
  return `${prefix}${randomNum}`;
}
export function PaginationList(array, page_size, page_number) {
  if (array) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  } else {
    return [];
  }
}
export function roundToFixed(num: number, precision: number): number {
  return parseFloat(
    (+(Math.round(+(num + "e" + precision)) + "e" + -precision)).toFixed(
      precision
    )
  );
}

export const onSelectLocation = (data, type, setValue) => {
  setValue(type, {
    ...data,
    address: data?.address + ", " + data?.postal_code,
  });
};

export function getHighestNumber(mix_sub_list: any, full_num: boolean = false): any{
  let highestValue: number | null = null;
  let highestItem: string | null = null;

  mix_sub_list?.forEach((item: any) => {
    const match = item?.transfer_document_number?.match(/([A-Za-z]+-?)(\d+)/);
    if (match) {
      const currentNumber = parseInt(match[2], 10); // Extract the numeric part
      if (highestValue === null || currentNumber > highestValue) {
        highestValue = currentNumber;
        highestItem = item.transfer_document_number; // Keep the full string
      }
    }
  });

  // Return either the full string or just the numeric part based on full_num
  if (full_num) {
    return highestItem || ""; // Return the full string or empty string if no match found
  }
  console.log("getHighestNumber",mix_sub_list,highestValue);
  
  return highestValue !== null ? highestValue : 0; // Return 0 if no valid numbers found
}




export function getLowestNumber(mix_sub_list: any, full_num?: boolean):any{
  let lowestValue: number | null = null;
  let lowestItem: string | null = null;

  mix_sub_list?.forEach((item: string) => {
    const match = item?.match(/([A-Za-z]+-?)(\d+)/);
    if (match) {
      const currentNumber = parseInt(match[2], 10); // Extract the numeric part
      if (lowestValue === null || currentNumber < lowestValue) {
        lowestValue = currentNumber;
        lowestItem = item; // Keep the full string
      }
    }
  });

  // Return either the full string or just the numeric part based on full_num
  if (full_num) {
    return lowestItem || ""; // Return the full string or empty string if no match found
  }
  return lowestValue !== null ? lowestValue : 0; // Return 0 if no valid numbers found
}


export function getNextPickupNumber(
  pickup_number: string,
  increment: number
): string {
  console.log(`"nextNumber1"${pickup_number}`);
  const match = pickup_number?.match(/([A-Za-z]+-?)(\d+)/);
  if (!match) {
    console.error("Invalid pickup number format.");
    return pickup_number;
  }

  const prefix = match[1]; // Extract prefix (e.g., KM)
  const currentNumber = parseInt(match[2], 10); // Extract current numeric part
  if (isNaN(currentNumber)) {
    console.error("Invalid numeric value in pickup number.");
    return pickup_number;
  }

  // Generate the next pickup number
  const nextNumber = increment.toString().padStart(match[2].length, "0"); // Keep leading zeros
  console.log(`"nextNumber"${prefix}${nextNumber}`);
  
  return `${prefix}${nextNumber}`;
}

export function giveSeriesSplit(item,splitNum:string){
  if(splitNum=="primary")
  return item?.match(/([A-Za-z]+-?)(\d+)/)[1]
  if(splitNum=="Secondary")
    return item?.match(/([A-Za-z]+-?)(\d+)/)[2]
}


export function callOnPickUpTXNChange(data,setValue,PreviewPopup,setError,dispatch,send,pickup_number,type,stockData:any={}) {
  setValue("transfer_document_number", data?.value?.toUpperCase());
  let isValid 
  if(type=="edit_list"){
    isValid = PreviewPopup?.items?.find(
      (item) => item.transfer_document_number === data?.value
    );
  }
  else{
    isValid = PreviewPopup?.mix_sub_list?.find(
      (item) => item.transfer_document_number === data?.value
    );
  }
  console.log("isValid::",isValid,PreviewPopup?.data?.id);
  
  if (
    isValid?.transfer_document_number === data?.value &&
    (type==="edit_list"?stockData?.id:PreviewPopup?.data?.id) !== isValid?.id
  ) {
    setError("transfer_document_number", { message: "No. already used" });
    return;
  } else {
    const req = {
      type: "inventoryTransferService",
      action: "checkPickupNumber",
      payload: { transfer_document_number: data?.value },
      demo: { search: true, isValid: ((type==="edit_list"?stockData?.id:PreviewPopup?.data?.id===undefined?true:PreviewPopup?.data?.id) !== isValid?.id) },
    };
    send(req);
  }
  dispatch(setPickupNo({ ...pickup_number, last_number: data?.value }));
}